.closeButton {
  padding: 4px;
}

.title {
  align-items: center;
}

.fixedHeight {
  max-height: 45vh;
  min-height: 45vh;
  padding: 0 !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.contentText {
  margin: 0;
}

.footer {
  padding: 8px 20px 8px 8px;
}